import Rollbar from 'rollbar';

import { isIos } from './helpers/os';
import {
  PurchaseProducts,
  Purchase,
  SettingsRecommendationsReducerState,
  PermissionStatus,
} from './store/interfaces';
import { User } from './types/user';
import { NativeMessage } from './services/NativeMessageService';
import { eventsService } from './services/EventsService';
import { rollbar } from './config/rollbar';

/**
 * Do not edit those functions, they are used in native apps by clients (iOs and Android)
 */

declare global {
  interface Window {
    searchStart(): void;
    searchStop(): void;

    setBackgroundLocation(lat: number, lng: number): void;

    setParams(headers: any): void;
    setDeviceToken(deviceToken: string): void;
    setApiUrl(apiUrl: string): void;

    updateCredits(): void;
    showSessionExpiredModal(): void;

    setLocation(url: string): void;

    JSBridge: {
      postMessage(value: string): void;
    };
    webkit: {
      messageHandlers: {
        observer: {
          postMessage(value: NativeMessage): void;
        };
      };
    };
    rollbar: Rollbar;
    setPurchaseProducts(products: PurchaseProducts): void;
    validatePurchase(data: Purchase): void;
    nativeNetworkResponse(type: string, response: string): void;
    setUser(user: User): void;
    setPermissionStatus(
      permission: keyof SettingsRecommendationsReducerState['permissions'],
      status: PermissionStatus
    ): void;
    setGetRidesFulfill(response: any, timestamp: number): void;
    setDropRideFulfill(response: any, id: number): void;
    sendLog(data: object): void;
  }
}

window.setGetRidesFulfill = eventsService.setGetRidesFulfill;
window.setDropRideFulfill = eventsService.setDropRideFulfill;
window.searchStart = eventsService.searchStart;
window.nativeNetworkResponse = eventsService.nativeNetworkResponse;
window.searchStop = eventsService.searchStop;
window.setParams = eventsService.setParams;
window.setLocation = eventsService.setLocation;
// @ts-ignore
window.setPermissionStatus = eventsService.setPermissionStatus;
window.setBackgroundLocation = eventsService.setBackgroundLocation;
window.setDeviceToken = eventsService.setDeviceToken;
window.setApiUrl = eventsService.setApiUrl;
window.setPurchaseProducts = eventsService.setPurchaseProducts;
window.validatePurchase = eventsService.validatePurchase;
window.setUser = eventsService.setUser;
window.updateCredits = eventsService.updateCredits;
window.sendLog = eventsService.sendLog;
window.showSessionExpiredModal = eventsService.showSessionExpiredModal;
window.rollbar = rollbar;

window.onload = function OnWindowLoad() {
  // disable long touch
  if (isIos) {
    try {
      document.documentElement.style.webkitUserSelect = 'none';
      // @ts-ignore
      document.body.style.webkitTouchCallout = 'none';
      // @ts-ignore
      document.body.style.KhtmlUserSelect = 'none';
    } catch (e) {
      console.error(e);
    }
  }
};
