export function apiCallDecorator(apiCall: { call: (arg0: any, arg1: any) => any }) {
  return async function (...args: any) {
    try {
      // @ts-ignore
      const response = await apiCall.call(this, ...args);
      return response.data.data;
    } catch (e) {
      const statusCode = e.response?.status ?? 502;

      // Если нет response или статус < 500, просто возвращаем ошибку без логирования
      if (!e.response) {
        return { code: statusCode };
      }

      if (statusCode >= 500) {
        // Если есть response и статус >= 500, логируем
        const fullUrl = e.config?.baseURL
          ? `${e.config.baseURL}${e.config.url}`
          : e.config?.url || null;

        const errorDetails = {
          tag: 'App:HttpError',
          code: statusCode,
          url: fullUrl,
          method: e.config?.method || null,
          headers: e.config?.headers || {},
          request: e.config?.data || null,
          response: e.response?.data || null,
        };

        window.sendLog(errorDetails);
      }

      return e.response.data.data || { code: statusCode };
    }
  };
}

export const generateAndroidIdentifiers = (accountIdentifierToken: string) => {
  return [
    {
      type: 'android_backup_token',
      name: accountIdentifierToken,
      source: 'lyft_android_app',
    },
  ];
};
