import {
  Purchase,
  PurchaseProducts,
  SettingsRecommendationsReducerState,
} from '../store/interfaces';
import { User } from '../types/user';
import { SearchFilter } from '../core/settings/interfaces';
import { createDebug, isDebugOn } from '../helpers/debug';

import storageService, { StorageService, StorageKeys } from './StorageService';

const debug = createDebug.extend('eventService');

export enum Events {
  SetSearchStatus = 'SetSearchStatus',
  SetClientHeaders = 'SetClientHeaders',
  SetDeviceToken = 'SetDeviceToken',
  SetApiUrl = 'SetApiUrl',
  SetPurchaseProducts = 'SetPurchaseProducts',
  ValidatePurchase = 'ValidatePurchase',
  SetUser = 'SetUser',
  ShowInAppReviewModal = 'ShowInAppReviewModal',
  ShowClientMessage = 'ShowClientMessage',
  UpdateCreditsMessage = 'UpdateCreditsMessage',
  ShowInfoModal = 'ShowInfoModal',
  ToggleSettingsFilter = 'ToggleSettingsFilter',
  ShowCreditsChangedModal = 'ShowCreditsChangedModal',
  AppVisibilityChange = 'AppVisibilityChangeEvent',
  ShowSupportModal = 'ShowSupportModalEvent',
  ShowRecommendations = 'ShowRecommendations',
  SetBackgroundLocation = 'SetBackgroundLocation',
  SetPermissionStatus = 'SetPermissionStatus',
  NativeNetworkResponseEvent = 'NativeNetworkResponseEvent',
  GetRidesFulfillEvent = 'GetRidesFulfillEvent',
  DropRideFulfillEvent = 'DropRideFulfillEvent',
  SendLog = 'SendLog',
  SetLocation = 'SetLocation',
}

export interface SetSearchStatusEvent extends CustomEvent {
  detail: {
    status: boolean;
  };
}

export interface NativeNetworkResponseEvent extends CustomEvent {
  detail: {
    type: string;
    response: string;
  };
}

export interface GetRidesFulfillEvent extends CustomEvent {
  detail: {
    response: any;
    timestamp: number;
  };
}

export interface DropRideFulfillEvent extends CustomEvent {
  detail: {
    response: any;
    id: number;
  };
}

export interface SetBackgroundLocationEvent extends CustomEvent {
  detail: {
    lat: number;
    lng: number;
  };
}

export interface SetClientHeadersEvent extends CustomEvent {
  detail: {
    clientHeaders: string;
  };
}

export interface SetLocationEvent extends CustomEvent {
  detail: {
    url: string;
  };
}

export interface SetDeviceTokenEvent extends CustomEvent {
  detail: {
    deviceToken: string;
  };
}

export interface SetApiUrlEvent extends CustomEvent {
  detail: {
    apiUrl: string;
  };
}

export interface SetPurchaseProductsEvent extends CustomEvent {
  detail: {
    products: PurchaseProducts;
  };
}

export interface ValidatePurchaseEvent extends CustomEvent {
  detail: {
    purchase: Purchase;
  };
}

export interface ShowInfoModalEvent extends CustomEvent {
  detail: {
    title: string;
    body: string;
  };
}

export interface SetUserEvent extends CustomEvent {
  detail: {
    user: User;
  };
}

export interface ShowInAppModalEvent extends CustomEvent {
  detail: {
    selfClicked: boolean;
  };
}

export interface ShowClientMessageEvent extends CustomEvent {
  detail: {
    message: string;
    errorCode: number;
  };
}

export interface UpdateCreditsMessageEvent extends CustomEvent {
  detail: null;
}

export interface SendLogMessageEvent extends CustomEvent {
  detail: {
    data: {
      [key: string]: any;
    };
  };
}

export interface ShowCreditsChangedModalEvent extends CustomEvent {
  detail: null;
}

export interface ToggleSettingsFilterEvent extends CustomEvent {
  detail: {
    filterName: SearchFilter;
  };
}

export interface AppVisibilityChangeEvent extends CustomEvent {
  detail: {
    isVisible: boolean;
  };
}

export interface SetPermissionStatusEvent extends CustomEvent {
  detail: {
    permission: keyof SettingsRecommendationsReducerState['permissions'];
    status: PermissionStatus;
  };
}

export type ShowSupportModalEvent = CustomEvent;

export class EventsService {
  protected isRegistered = false;

  private storageService: StorageService;

  constructor({ storageService }) {
    this.storageService = storageService;
  }

  /*
  response: {
   data: responseBody,
   status: response.code
  }
   */
  nativeNetworkResponse = (type: string, response: string) => {
    this.dispatchEvent<NativeNetworkResponseEvent>(Events.NativeNetworkResponseEvent, {
      type,
      response,
    });
  };

  setGetRidesFulfill = (response: any, timestamp: number) => {
    this.dispatchEvent<GetRidesFulfillEvent>(Events.GetRidesFulfillEvent, {
      response,
      timestamp,
    });
  };

  setDropRideFulfill = (response: any, id: number) => {
    this.dispatchEvent<DropRideFulfillEvent>(Events.DropRideFulfillEvent, {
      response,
      id,
    });
  };

  searchStart = () => {
    this.dispatchEvent<SetSearchStatusEvent>(Events.SetSearchStatus, {
      status: true,
    });
  };

  searchStop = () => {
    this.dispatchEvent<SetSearchStatusEvent>(Events.SetSearchStatus, { status: false });
  };

  setParams = (clientHeaders: string) => {
    this.dispatchEvent<SetClientHeadersEvent>(Events.SetClientHeaders, {
      clientHeaders,
    });
  };

  setLocation = (url: string) => {
    this.dispatchEvent<SetLocationEvent>(Events.SetLocation, {
      url,
    });
  };

  setPermissionStatus = (
    permission: keyof SettingsRecommendationsReducerState['permissions'],
    status: PermissionStatus
  ) => {
    this.dispatchEvent<SetPermissionStatusEvent>(Events.SetPermissionStatus, {
      permission,
      status,
    });
  };

  setDeviceToken = (deviceToken: string) => {
    this.dispatchEvent<SetDeviceTokenEvent>(Events.SetDeviceToken, { deviceToken });
  };

  setApiUrl = (apiUrl: string) => {
    this.dispatchEvent<SetApiUrlEvent>(Events.SetApiUrl, { apiUrl });
  };

  setBackgroundLocation = (lat: number, lng: number) => {
    this.dispatchEvent<SetBackgroundLocationEvent>(Events.SetBackgroundLocation, { lat, lng });
  };

  setPurchaseProducts = (products: PurchaseProducts) => {
    this.dispatchEvent<SetPurchaseProductsEvent>(Events.SetPurchaseProducts, {
      products,
    });
  };

  validatePurchase = (purchase: Purchase) => {
    setTimeout(() => {
      this.dispatchEvent<ValidatePurchaseEvent>(Events.ValidatePurchase, { purchase });
    }, 2000);
  };

  setUser = (user: User) => {
    this.dispatchEvent<SetUserEvent>(Events.SetUser, { user });
  };

  showInAppReviewModal = (selfClicked: boolean) => {
    this.dispatchEvent<ShowInAppModalEvent>(Events.ShowInAppReviewModal, {
      selfClicked,
    });
  };

  showInfoModal = ({ title, body }: { title: string; body: string }) => {
    this.dispatchEvent<ShowInfoModalEvent>(Events.ShowInfoModal, {
      title,
      body,
    });
  };

  updateCredits = () => {
    this.dispatchEvent<UpdateCreditsMessageEvent>(Events.UpdateCreditsMessage);
  };

  sendLog = (data: object) => {
    this.dispatchEvent<SendLogMessageEvent>(Events.SendLog, {
      data,
    });
  };

  showClientMessage = (message: string, errorCode: number) => {
    this.dispatchEvent<ShowClientMessageEvent>(Events.ShowClientMessage, {
      message,
      errorCode,
    });
  };

  showSessionExpiredModal = () => {
    this.dispatchEvent<ShowClientMessageEvent>(Events.ShowClientMessage, {
      message: 'Session has been expired',
      errorCode: -3,
    });
  };

  showCreditsChangedModal = () => {
    this.dispatchEvent<ShowCreditsChangedModalEvent>(Events.ShowCreditsChangedModal);
  };

  toggleSettingsFilter = (filterName: SearchFilter) => {
    this.dispatchEvent<ToggleSettingsFilterEvent>(Events.ToggleSettingsFilter, {
      filterName,
    });
  };

  triggerAppVisible = (isVisible: boolean) => {
    this.dispatchEvent<AppVisibilityChangeEvent>(Events.AppVisibilityChange, {
      isVisible,
    });
  };

  showSupportModal = () => {
    this.dispatchEvent<ShowSupportModalEvent>(Events.ShowSupportModal);
  };

  private handleSetClientHeaders = (event: SetClientHeadersEvent) => {
    this.storageService.setItem(StorageKeys.clientHeaders, event.detail.clientHeaders);

    const result = JSON.stringify({
      headers: this.storageService.getItem(StorageKeys.clientHeaders),
    });

    console.info(result);
  };

  private handleSetDeviceToken = (event: SetDeviceTokenEvent) => {
    this.storageService.setItem(StorageKeys.isMobileApp, '1');
    this.storageService.setItem(StorageKeys.deviceToken, event.detail.deviceToken);
    this.storageService.removeItem(StorageKeys.notificationAuthToken);
  };

  private handleSetApiUrl = (event: SetApiUrlEvent) => {
    this.storageService.setItem(StorageKeys.apiUrl, `${event.detail.apiUrl}/api/client/v1`);

    const result = JSON.stringify({
      apiUrl: this.storageService.getItem(StorageKeys.apiUrl),
    });

    console.info(result);
  };

  addEventListeners = () => {
    if (this.isRegistered) {
      return;
    }

    this.isRegistered = true;

    this.on(Events.SetApiUrl, this.handleSetApiUrl);
    this.on(Events.SetDeviceToken, this.handleSetDeviceToken);
    this.on(Events.SetClientHeaders, this.handleSetClientHeaders);
  };

  removeEventListeners = () => {
    this.isRegistered = false;

    this.off(Events.SetApiUrl, this.handleSetApiUrl);
    this.off(Events.SetDeviceToken, this.handleSetDeviceToken);
    this.off(Events.SetClientHeaders, this.handleSetClientHeaders);
  };

  dispatchEvent = <T extends CustomEvent>(event: Events, data?: any) => {
    const customEvent = new CustomEvent<T>(event, {
      detail: data,
    });

    debug('dispatchEvent', customEvent.type, customEvent.detail);

    document.dispatchEvent(customEvent);
  };

  on = (eventName: Events, handler, capture = false) => {
    if (isDebugOn()) {
      const eventHandler = (event: CustomEvent) => {
        debug(`Run handler for "${eventName}"`, event.detail, handler);
      };
      document.addEventListener(eventName, eventHandler as EventListener, capture);
    }

    document.addEventListener(eventName, handler as EventListener, capture);
  };

  off = (eventName: Events, handler, capture = false) => {
    document.removeEventListener(eventName, handler as EventListener, capture);
  };
}

export const eventsService = new EventsService({ storageService });
